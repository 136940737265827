/* eslint-disable no-underscore-dangle */
import { createPathToRegexpParser } from '@folklore/routes';
import { HydrationBoundary, QueryClientProvider, hydrate } from '@tanstack/react-query';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Router } from 'wouter';

import Api from './lib/api/Api';
import defaultRichTextElements from './lib/defaultRichTextElements';
import getDevice from './lib/getDevice';

// import installAds from './ads';
import App from './components/App';
import Html from './components/Html';
import { ApiContextProvider } from './contexts/ApiContext';
import { RequestContextProvider } from './contexts/RequestContext';
import { SiteContextProvider } from './contexts/SiteContext';
import createQueryClient from './queryClient';

// import { prefetchAuthUser } from './hooks/useAuth';

const queryClient = createQueryClient();

const pathToRegexpParser = createPathToRegexpParser();

// const embeddedStyles = window.__ENTRYPOINTS_CSS || null;
const locale = 'fr';
const embeddedStylesElement = document.getElementById('embedded-styles') || null;
const embeddedStyles = embeddedStylesElement !== null ? embeddedStylesElement.innerText : null;
const embeddedScriptsElement = document.getElementById('embedded-scripts') || null;
const embeddedScripts = embeddedScriptsElement !== null ? embeddedScriptsElement.innerText : null;
const presentation = window.__PRESENTATION || null;
const site = window.__SITE || null;
const siteHandle = window.__SITE_HANDLE || 'urbania';
const translations = window.__TRANSLATIONS;
const ssrPath = window.__SSR_PATH;
const assetsManifest = window.__ASSETS_MANIFEST;
const isRedirector = window.__IS_REDIRECTOR || false;
const facebookAppId = window.__FACEBOOK_APP_ID || '1051760225433251';
const dehydratedState = window.__QUERY_STATE || null;
const googleClientId =
    window.__GOOGLE_CLIENT_ID ||
    '432379609774-r4t5037c96fnp2ja3te6dq9i74dke3uq.apps.googleusercontent.com';

const api = new Api({
    baseUrl: window.__API_BASE_URL || 'https://api.urbania.ca',
    authBaseUrl: window.__AUTH_BASE_URL || 'https://api.urbania.ca/auth',
});

const device = window.__DEVICE || getDevice();

// prefetchAuthUser(queryClient, api);

hydrateRoot(
    document,
    <Router parser={pathToRegexpParser} ssrPath={ssrPath}>
        <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={dehydratedState}>
                <IntlProvider
                    locale={locale}
                    messages={translations}
                    defaultRichTextElements={defaultRichTextElements}
                >
                    <RequestContextProvider
                        host={window.location.host}
                        protocol={window.location.protocol}
                    >
                        <ApiContextProvider client={api}>
                            <SiteContextProvider
                                site={site}
                                handle={siteHandle}
                                isRedirector={isRedirector}
                            >
                                <App
                                    presentation={presentation}
                                    device={device}
                                    isRedirector={isRedirector}
                                    facebookAppId={facebookAppId}
                                    googleClientId={googleClientId}
                                    assetsManifest={assetsManifest}
                                    embeddedStyles={embeddedStyles}
                                    embeddedScripts={embeddedScripts}
                                    withHtml
                                />
                            </SiteContextProvider>
                        </ApiContextProvider>
                    </RequestContextProvider>
                </IntlProvider>
            </HydrationBoundary>
        </QueryClientProvider>
    </Router>,
    {
        onRecoverableError: (error, { componentStack }) =>
            console.log('HYDRATE', error.message, componentStack),
    },
);
