/* eslint-disable react/no-danger */
import { useUrlGenerator } from '@folklore/routes';
import { ca, jaCountryTranslations } from 'intl-tel-input/i18n';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useParams } from 'wouter';

import { useTracking, useTrackingUpdatePage } from '../../hooks/useTracking';
import { useCanonicalUrl } from '../../hooks/useUrlGenerator';
import * as AppPropTypes from '../../lib/PropTypes';
import getOptimalImageSize from '../../lib/getOptimalImageSize';

import { useRequestBase } from '../../contexts/RequestContext';
import { useBrand, useDefaultBrand, useSite } from '../../contexts/SiteContext';

import baseFavicon from '../../assets/img/favicon/urbania.png';

const propTypes = {
    pageType: PropTypes.string,
    brand: AppPropTypes.brand,
    document: AppPropTypes.document,
    url: PropTypes.string,
    title: PropTypes.string,
    titleSuffix: PropTypes.string,
    description: PropTypes.string,
    image: AppPropTypes.image,
    favicon: AppPropTypes.image,
    keywords: PropTypes.arrayOf(PropTypes.string),
    rssUrl: PropTypes.string,
    atomUrl: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    microformats: PropTypes.arrayOf(PropTypes.object),
    withoutTitleSuffix: PropTypes.bool,
    noIndex: PropTypes.bool,
    trackingVariables: PropTypes.object,
};

const defaultProps = {
    pageType: null,
    brand: null,
    document: null,
    url: null,
    title: null,
    titleSuffix: null,
    description: null,
    image: null,
    favicon: null,
    keywords: null,
    rssUrl: '/articles.rss',
    atomUrl: '/articles.atom',
    microformats: null,
    withoutTitleSuffix: false,
    noIndex: false,
    trackingVariables: null,
};

function Metadata({
    pageType,
    brand: providedBrand,
    document,
    title,
    description,
    image,
    favicon,
    url,
    keywords,
    rssUrl,
    atomUrl,
    microformats,
    titleSuffix,
    withoutTitleSuffix,
    noIndex,
    trackingVariables,
}) {
    const { handle: siteHandle, isRedirector = false } = useSite();
    const { brand: brandParam = null } = useParams();
    const baseUri = useRequestBase();
    const defaultBrand = useDefaultBrand();
    const canonicalUrl = useCanonicalUrl();
    const generateUrl = useUrlGenerator();
    const { handle: brandHandle = null } = providedBrand || {};
    const brand = useBrand(brandHandle || brandParam);
    const finalBrand = brand || providedBrand;
    const {
        label: brandLabel = null,
        description: brandDescription = null,
        slug: brandSlug = null,
        images: {
            favicon: brandFavicon = null,
            share: brandShareImage = null,
            logo: brandLogo = null,
        } = {},
    } = finalBrand;
    const { images: { logo: defaultBrandLogo = null } = {} } = defaultBrand;
    const { url: brandLogoUrl = null } = brandLogo || {};
    const { url: defaultBrandLogoUrl = null } = defaultBrandLogo || {};
    const brandTitleSuffix = brandLabel !== null ? ` | ${brandLabel}` : '';
    const finalTitleSuffix = titleSuffix || brandTitleSuffix;
    const imageAtSize = getOptimalImageSize(image || brandShareImage, 1920, 1080);
    const finalFavicon = favicon || brandFavicon;
    const { url: faviconUrl = baseFavicon } = finalFavicon || {};
    const finalDescription = description || brandDescription;
    const finalKeywords = keywords !== null ? keywords.join(',') : null;
    let metaUrl = null;
    if (url !== null) {
        metaUrl = url.match(/^(https?)?:\/\//i) === null ? `${baseUri}${url}` : url;
    }
    let canonical = null;
    if (url !== null && !isRedirector) {
        canonical = canonicalUrl(url);
    }
    const finalRssUrl =
        rssUrl !== null && rssUrl.match(/^(https?)?:\/\//i) === null
            ? `${baseUri}${rssUrl}`
            : rssUrl;
    const finalAtomUrl =
        atomUrl !== null && atomUrl.match(/^(https?)?:\/\//i) === null
            ? `${baseUri}${atomUrl}`
            : rssUrl;
    const finalTitle = [title, !withoutTitleSuffix ? finalTitleSuffix : null]
        .filter((it) => it !== null)
        .join('');

    const tracking = useTracking();
    const pageVariables = useMemo(
        () =>
            document !== null
                ? tracking.getPageVariablesFromDocument(document, trackingVariables)
                : tracking.getPageVariables({
                      pageTitle: finalTitle,
                      pageType,
                      brand: finalBrand,
                      ...trackingVariables,
                  }),
        [pageType, document, finalBrand, trackingVariables, finalTitle],
    );

    return (
        <>
            <link rel="icon" href={faviconUrl} type="image/png" />

            {/* General */}
            {finalTitle !== null && finalTitle.length > 0 ? <title>{finalTitle}</title> : null}
            {finalDescription !== null ? (
                <meta name="description" content={finalDescription} />
            ) : null}
            {finalKeywords !== null ? <meta name="keywords" content={finalKeywords} /> : null}

            {canonical !== null ? <link rel="canonical" href={canonical} /> : null}

            {noIndex ? <meta name="robots" content="noindex" /> : null}

            {/* Feeds */}
            {finalRssUrl !== null ? (
                <link rel="alternate" type="application/rss+xml" href={finalRssUrl} />
            ) : null}
            {finalAtomUrl !== null ? (
                <link rel="alternate" type="application/atom+xml" href={finalAtomUrl} />
            ) : null}

            {/* Open graph */}
            <meta property="og:type" content="website" />
            {imageAtSize !== null ? <meta property="og:image" content={imageAtSize.url} /> : null}
            {imageAtSize !== null ? (
                <meta property="og:image:width" content={imageAtSize.width} />
            ) : null}
            {imageAtSize !== null ? (
                <meta property="og:image:height" content={imageAtSize.height} />
            ) : null}
            <meta property="og:title" content={title} />
            {finalDescription !== null ? (
                <meta property="og:description" content={finalDescription} />
            ) : null}
            {metaUrl !== null ? <meta property="og:url" content={metaUrl} /> : null}

            {/* Twitter */}
            <meta
                name="twitter:site"
                content={siteHandle === 'france' ? '@URBANIAfr' : '@_URBANIA'}
            />
            <meta
                name="twitter:card"
                content={imageAtSize !== null ? 'summary_large_image' : 'summary'}
            />
            <meta name="twitter:title" content={title} />
            {finalDescription !== null ? (
                <meta name="twitter:description" content={finalDescription} />
            ) : null}
            {imageAtSize !== null ? <meta name="twitter:image" content={imageAtSize.url} /> : null}

            {/* Microformats */}
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        '@context': 'http://schema.org/',
                        '@type': 'NewsMediaOrganization',
                        identifier: canonicalUrl('/'),
                        name: 'URBANIA',
                        url: canonicalUrl('/'),
                        logo: defaultBrandLogoUrl,
                    }),
                }}
            />
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        '@context': 'http://schema.org/',
                        '@type': 'Brand',
                        identifier: canonicalUrl(generateUrl('home', { brand: brandSlug })),
                        name: brandLabel,
                        url: canonicalUrl(generateUrl('home', { brand: brandSlug })),
                        logo: brandLogoUrl,
                    }),
                }}
            />
            {(microformats || [])
                .filter((it) => it !== null)
                .map((it) => (
                    <script
                        type="application/ld+json"
                        key={`microformat-${it[`@type`]}-${it.identifier}`}
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(it),
                        }}
                    />
                ))}

            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];window.dataLayer.push(${JSON.stringify(pageVariables)})`,
                }}
            />
        </>
    );
}

Metadata.propTypes = propTypes;
Metadata.defaultProps = defaultProps;

export default Metadata;
