import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function HomeMetadata() {
    const { brand: brandParam = null, section: sectionParam = null } = useParams();
    const defaultBrand = useDefaultBrand();
    const brand = useBrand(brandParam);
    const finalBrand = brandParam !== null ? brand : defaultBrand;
    const { label: brandLabel, sections, tagline, description, metadata } = finalBrand || {};
    const { title: brandTitle = null, description: brandDescription = null } = metadata || {};
    const section =
        sectionParam !== null ? sections.find(({ slug }) => slug === sectionParam) || null : null;
    const isSection = section !== null;
    const { label: sectionLabel = null, metadata: sectionMetadata } = section || {};
    const { title: sectionTitle = null, description: sectionDescription = null } =
        sectionMetadata || {};
    const url = useUrlGenerator();
    const metadataTitle = isSection ? sectionTitle : brandTitle;
    const metadataDescription = (isSection ? sectionDescription : null) || brandDescription;
    return (
        <Metadata
            brand={finalBrand}
            title={metadataTitle || (isSection ? sectionLabel : `${brandLabel} - ${tagline}`)}
            description={metadataDescription || description}
            withoutTitleSuffix={!isSection || (metadataTitle !== null && !isSection)}
            url={url('home.wildcard', {
                brand: brandParam,
                section: sectionParam,
            })}
            rssUrl={`/${brandParam || 'articles'}.rss`}
            atomUrl={`/${brandParam || 'articles'}.atom`}
            pageType="home"
        />
    );
}

HomeMetadata.propTypes = propTypes;
HomeMetadata.defaultProps = defaultProps;

export default HomeMetadata;
