import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import usePage from '../../hooks/usePage';

import Metadata from './Metadata';
import NotfoundMetadata from './NotfoundMetadata';

const propTypes = {};

const defaultProps = {};

function PageMetadata() {
    const { slug: slugParam } = useParams();
    const { page = null } = usePage(slugParam);
    const url = useUrlGenerator();
    if (page === null) {
        return <NotfoundMetadata />;
    }
    const { title, slug, image } = page;
    return (
        <Metadata
            title={title}
            image={image}
            url={url('page', {
                slug,
            })}
            pageType="page"
        />
    );
}

PageMetadata.propTypes = propTypes;
PageMetadata.defaultProps = defaultProps;

export default PageMetadata;
