/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from 'react-intl';

import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function NotfoundMetadata(props) {
    const intl = useIntl();
    return (
        <Metadata
            title={intl.formatMessage({
                defaultMessage: 'Page introuvable',
                description: 'Page title',
            })}
            pageType="404"
            {...props}
        />
    );
}

NotfoundMetadata.propTypes = propTypes;
NotfoundMetadata.defaultProps = defaultProps;

export default NotfoundMetadata;
