import { useUrlGenerator } from '@folklore/routes';
import React, { useMemo } from 'react';
import { useParams } from 'wouter';

import useFormat from '../../hooks/useFormat';

import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function FormatMetadata() {
    const { slug: slugParam } = useParams();
    const { format = null } = useFormat(slugParam);
    const url = useUrlGenerator();
    if (format === null) {
        return null;
    }
    const { label, slug, image, brand, metadata } = format;
    const { title, description } = metadata || {};
    const trackingVariables = useMemo(
        () => ({
            categories: [format],
            formats: [format],
        }),
        [format],
    );
    return (
        <Metadata
            brand={brand}
            title={title || label}
            description={description}
            image={image}
            url={url('format', {
                slug,
            })}
            pageType="format"
            trackingVariables={trackingVariables}
        />
    );
}

FormatMetadata.propTypes = propTypes;
FormatMetadata.defaultProps = defaultProps;

export default FormatMetadata;
